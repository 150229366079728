<template>
  <div id="account-settings">
    <Headline />
    <AccountSettings />
  </div>
</template>

<script>
import AccountSettings from '../../components/account/AccountSettings'
import Headline from '@/components/app/Headline.vue'

export default {
  components: {
    Headline,
    AccountSettings
  }
}
</script>
